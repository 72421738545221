import React, { useContext, useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-scroll';
import PortfolioContext from '../../context/context';

const Header = () => {
  const { hero } = useContext(PortfolioContext);
  const { title, cta, waitlist, subtitle, stockStatus } = hero;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="hero" className="">
      <div style={{ top: '10%' }} className="hero-banner" />
      <div style={{ top: '17%' }} className="hero-banner" />
      <div style={{ top: '24%' }} className="hero-banner" />
      <div style={{ top: '31%' }} className="hero-banner" />
      <div style={{ top: '38%' }} className="hero-banner" />
      <div style={{ top: '45%' }} className="hero-banner" />
      <div style={{ top: '52%' }} className="hero-banner" />
      {/* <div style={{ top: '59%' }} className="hero-banner" />
      <div style={{ top: '66%' }} className="hero-banner" /> */}

      <Container>
        <h1 className="hero-title">
          <Fade left={isDesktop} bottom={isMobile} duration={900} delay={100} distance="30px">
            <span className="text-color-main">{title} </span>
          </Fade>
          <Fade left={isDesktop} bottom={isMobile} duration={900} delay={700} distance="30px">
            <span>{subtitle}</span>
          </Fade>
        </h1>
        <Fade left={isDesktop} bottom={isMobile} duration={900} delay={1300} distance="30px">
          <p className="hero-cta">
            <span className="cta-btn cta-btn--hero">
              <Link to="projects" smooth duration={1000}>
                {cta || 'Learn more'}
              </Link>
            </span>
            <a className="cta-btn cta-btn--hero" href="https://dash.ksrautomation.co/products/ksr/81f07bb37373c399e12d6f03a0726cef070a51a0" smooth duration={1000}>
                {waitlist || 'Join Waitlist'}
            </a>
            <Link smooth duration={1000}>
              <span
                className={
                  stockStatus === 'Sold Out'
                    ? 'cta-btn cta-btn--disabled'
                    : ' cta-btn cta-btn--hero'
                }
              >
                {stockStatus || 'Sold out'}
              </span>
            </Link>
          </p>
        </Fade>
      </Container>
      {/* <HeroImg alt="KSR banner" filename={img} quality={100} />
      <HeroImg alt="KSR banner" filename={img} quality={100} />
      <HeroImg alt="KSR banner" filename={img} quality={100} /> */}
    </section>
  );
};

export default Header;
