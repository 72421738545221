import React, { useContext } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-scroll';
import Fade from 'react-reveal/Fade';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';

const Footer = () => {
  const { footer } = useContext(PortfolioContext);
  const { networks } = footer;

  return (
    <footer className="footer navbar-static-bottom">
      <Container>
        <Title title="STAY IN TOUCH" />
        <div className="social-links">
          {networks &&
            networks.map((network) => {
              const { id, name, url } = network;
              return (
                <Fade duration={800} delay={100} distance="30px">
                  <a
                    key={id}
                    href={url}
                    rel="noopener noreferrer"
                    target="_blank"
                    aria-label={name}
                  >
                    <i className={`fa fa-${name || 'refresh'} fa-inverse`} />
                  </a>
                </Fade>
              );
            })}
        </div>
        {/* <hr /> */}
        <div className="legal-links">
          {/* <h3>Privacy Policy</h3> */}
          <h3>
            <a href="/terms" rel="noopener noreeferrer" target="_blank" aria-label="terms">
              Terms and Conditions
            </a>
          </h3>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
