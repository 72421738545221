import React, { useContext, useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-scroll';
import Fade from 'react-reveal/Fade';
import PortfolioContext from '../../context/context';

const Nav = () => {
  const { nav } = useContext(PortfolioContext);
  const [open, setOpen] = useState(false);
  const { links } = nav;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
      setOpen(true);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
      setOpen(false);
    }
  }, []);

  return (
    <nav className="nav">
      <Container>
        <div className="nav-links">
          {links &&
            links.map((link) => {
              const { id, name, url, external } = link;
              return (
                <Fade right duration={600} delay={100} distance="30px">
                  {open && (
                    <span className="cta-btn cta-btn--nav" key={id} open={open} setOpen={setOpen}>
                      {external ? (
                        <a
                          key={id}
                          href={url}
                          rel="noopener noreferrer"
                          target="_blank"
                          aria-label={name}
                        >
                          {name}
                        </a>
                      ) : (
                        <Link to={url} smooth duration={1000}>
                          {name}
                        </Link>
                      )}
                    </span>
                  )}
                </Fade>
              );
            })}
          <Fade right duration={600} delay={100} distance="30px">
            <i
              className={open ? 'fa fa-times menu-toggle' : 'fa fa-bars menu-toggle'}
              open={open}
              setOpen={setOpen}
              onClick={() => setOpen(!open)}
            />
          </Fade>
        </div>
      </Container>
    </nav>
  );
};

export default Nav;
