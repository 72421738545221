import React, { useContext } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';

const Contact = () => {
  const { contact } = useContext(PortfolioContext);
  const { cta, btn, email, qa } = contact;

  return (
    <section id="contact">
      <Container>
        <Title title="Frequent Questions" />
        <Fade bottom duration={800} delay={300} distance="30px">
          <Row>
            {qa &&
              qa.map((item) => {
                const { question, answer } = item;
                return (
                  <Col className="set" lg={6} md={6} s={12} xs={12}>
                    <h1>{question}</h1>
                    <hr />

                    <h2>{answer}</h2>
                  </Col>
                );
              })}
          </Row>
        </Fade>
      </Container>
    </section>
  );
};

export default Contact;
